import { gsap, Power4 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.config({
  nullTargetWarn: false,
})

gsap.registerPlugin(ScrollTrigger)

const history = () => {
  const mediaQuery: MediaQueryList = window.matchMedia('(max-width: 828px)')
  const historyFlag = document.querySelector('.History')
  if (!historyFlag) {
    return
  }

  const items = document.querySelectorAll('.History__ContentTreeListItem')
  const historyResToggles = document.querySelectorAll('.HistoryResToggle')

  items.forEach((item) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top center',
      },
    })

    tl.to(item, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power4.easeInOut,
    })
  })

  if (mediaQuery.matches) {
    historyResToggles.forEach((historyResToggle: any) => {
      historyResToggle.addEventListener('click', ({ currentTarget }) => {
        // const stack = currentTarget.querySelector(
        //   '.History__ContentTreeListItemStack'
        // )
        currentTarget.classList.toggle('-Active')
      })
    })
  }
}

// const history = () => {
//   const historyFlag = document.querySelector('.History')
//   if (!historyFlag) {
//     return
//   }

//   const state = {
//     dom: {
//       scroll: document.querySelector('.side-scroll'),
//       scrollWrapper: document.querySelector('.side-scroll-list-wrapper'),
//       scrollList: document.querySelector('.side-scroll-list'),
//     },
//     headerHeight: document.querySelector('.Header').clientHeight,
//   }

//   const sideScroll = () => {
//     gsap.to(state.dom.scrollList, {
//       x: () =>
//         -(
//           state.dom.scrollList.clientWidth - state.dom.scrollWrapper.clientWidth
//         ),
//       ease: 'none',
//       scrollTrigger: {
//         trigger: '.side-scroll',
//         start: 'top 5%',
//         onToggle: (self) => {
//           const header = document.querySelector('.Header')
//           if (self.isActive) {
//             gsap.to(header, {
//               duration: 0.3,
//               y: `-${state.headerHeight}px`,
//             })
//           } else {
//             gsap.to(header, {
//               duration: 0.3,
//               y: 0,
//             })
//           }
//         },
//         end: () =>
//           `+=${
//             state.dom.scrollList.clientWidth -
//             state.dom.scrollWrapper.clientWidth
//           }`,
//         scrub: true,
//         pin: true,
//         anticipatePin: 1,
//         invalidateOnRefresh: true,
//         markers: true,
//       },
//     })
//   }

//   const init = () => {
//     // sideScroll()
//   }

//   init()
// }

export { history }
